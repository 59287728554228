<template>
  <div />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Naver",
  data() {
    return {
      message: "Hello World",
    };
  },
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.onLogin(code);
    } else {
      this.onBack();
    }
  },
  methods: {
    async onLogin(code) {
      try {
        await this.social({ site: "naver", code });
      } catch (e) {
        this.onBack();
      }
    },
    onBack() {
      this.$toast.open({ message: "네이버 로그인 실패", type: "error" });
      this.$router.push({ name: "Login" });
    },
    ...mapActions("user", ["social"]),
  },
};
</script>

<style></style>
